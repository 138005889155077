<template>
  <div class="grey lighten-4" style="height: calc(100vh - 88px)">
    <v-container class="grey lighten-4 py-1" fluid>
      <v-row align="center">
        <v-col cols="12" class="text-h6 text-uppercase font-weight-light">
          {{ tLabel("Truck Operations") }}
          <v-divider />
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="mb-3" v-if="roroVisitSelected === null">
      <v-row align="center">
        <v-col cols="12" class="mb-3 pb-0">
          <v-card elevation="2" rounded="0" class="mb-1" color="grey lighten-3">
            <v-card-text>
              <v-row align="center">
                <v-col cols="10" class="text-h5 text-uppercase font-weight-light">
                  {{ tLabel("Truck in yard") }}
                </v-col>
                <v-col cols="2" class="text-right">
                  <v-btn outlined color="primary" :title="tLabel('Refresh')" @click="searchRoro">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="4" class="my-0 py-0">
          <v-text-field v-model="searchFieldValue" :label="tLabel('License plate')" clearable prepend-inner-icon="mdi-magnify" @input="(val) => (searchFieldValue = val ? val.toUpperCase() : null)"/>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" class="mt-0 pt-0 scroller" v-if="roroVisitsFiltered !== null && roroVisitsFiltered.length > 0" style="height: calc(100vh - 287px)">
          <div v-for="rv in roroVisitsFiltered" :key="rv.id" @click="onClickRoro(rv)" class="cursor-vis">
            <v-hover v-slot="{ hover }" open-delay="50">
              <v-card :elevation="hover ? 6 : 2" rounded="0" :color="hover ? 'grey lighten-5' : null" class="mb-1">
                <v-card-text>
                  <v-container fluid class="ma-0 pa-0">
                    <v-row align="center">
                      <v-col cols="3" class="text-h6 font-weight-normal">
                        {{ rv.objectCode }}
                      </v-col>
                      <v-col cols="3">
                        {{ rv.personNameSurname }}
                      </v-col>
                      <v-col cols="2">
                        {{ rv.gateIn | formatDateTimeFromDate }}
                      </v-col>
                      <v-col cols="4">
                        {{ rv.remarks }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-hover>
          </div>
        </v-col>
        <v-col cols="12" class="text-subtitle-1 font-weight-light" v-else> Nessun truck in yard. </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="mb-3" v-else>
      <v-row align="center">
        <v-col cols="12" class="mb-0 pb-0">
          <v-card elevation="2" rounded="0" class="mb-1" color="blue lighten-5">
            <v-card-text>
              <v-row align="center">
                <v-col cols="1" class="text-left">
                  <v-icon x-large :title="tLabel('Back')" @click="searchRoro">mdi-arrow-left</v-icon>
                </v-col>
                <v-col cols="2" class="text-h5 text-uppercase font-weight-light">
                  {{ roroVisitSelected.objectCode }}
                </v-col>
                <v-col cols="3">
                  {{ roroVisitSelected.personNameSurname }}
                </v-col>
                <v-col cols="2">
                  {{ roroVisitSelected.gateIn | formatDateTimeFromDate }}
                </v-col>
                <v-col cols="4">
                  {{ roroVisitSelected.remarks }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" class="scroller" style="height: calc(100vh - 217px)">
          <DropoffPanel :truckVisitId="roroVisitSelected.id+''" editPositionVisible />
          <PickupPanel :truckVisitId="roroVisitSelected.id+''" addEnabled removeEnabled />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LabeledContent from "@/components/yard/LabeledContent.vue";
import DropoffPanel from "@/components/yard/DropoffPanel.vue";
import PickupPanel from "@/components/yard/PickupPanel.vue";
import FormatsMixins from "../../../mixins/FormatsMixins";

export default {
  name: "Operations",
  data() {
    return {
      searchFieldValue: null,
      roroVisits: null,
      roroVisitSelected: null
    };
  },
  components: { LabeledContent, DropoffPanel, PickupPanel },
  mixins: [FormatsMixins],
  async mounted() {
    await this.searchRoro();
  },
  computed: {
    roroVisitsFiltered() {
      if (this.roroVisits === null) {
        return null;
      }
      if (this.searchFieldValue !== null && this.searchFieldValue !== "") {
        return this.roroVisits.filter((rv) => rv.objectCode.toUpperCase().includes(this.searchFieldValue.toUpperCase()));
      }
      return this.roroVisits;
    },
  },
  methods: {
    async searchRoro() {
      this.roroVisitSelected = null;
      this.roroVisits = await this.$api.yardData.getTruckTransits();
      console.log("searchRoro roroVisits", this.roroVisits);
    },
    async onClickRoro(roro) {
      console.log("roro", roro);
      this.roroVisitSelected = JSON.parse(JSON.stringify(roro));
    },
  },
};
</script>

<style scoped>
.cursor-vis {
  cursor: pointer;
}
.scroller {
  overflow-y: auto;
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar {
  width: 6px;
}

.scroller::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroller::-webkit-scrollbar-thumb {
  background: #888;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
